<template>
    <div class="follow-page">
        <el-row :gutter="5">
            <el-col :span="17">
                <!-- style="height:calc(100vh - 88px)" -->
                <el-card class="left-area">
                    <!-- 头部信息 -->
                    <div class="flex-sb title-info">
                        <div class="flex title-left">
                            <div style="font-size: 16px;">客户信息</div>
                            <!-- :class="{ ping: scope.row.important == 1 }" -->
                            <div>
                                <i
                                    class="iconfont icon-aixin xin"
                                    :class="{ ping: data.important == 1 }"
                                    @click="zhongyao()"
                                />
                            </div>
                            <div>
                                <el-button type="warning" size="mini" @click="transfer">转移</el-button>
                                <el-button type="danger" size="mini" @click="dropPublicSea">丢公海</el-button>
                            </div>
                        </div>
                        <div class="flex title-right">
                            <div>
                                <img src="@/assets/searchPage/phone.png" alt />
                            </div>
                            <div>{{ common.hidePhoneNumber( data.mobileNumber ) }}</div>
                            <!-- 添加微信 -->
                            <div
                                v-if="wechatServiceEnabled"
                                slot="reference"
                                class="addWxBtn"
                                @click="openAddWxDialog"
                            >
                                <img src="../../assets/common/addWxBtn.png" />
                            </div>
                            <div>
                                <!-- <el-checkbox-group size="small" v-model="data.followStage">
                  <el-checkbox-button
                    v-for="item in followArr"
                    :label="item.number"
                    :key="item.number"
                    >{{ item.cnVal }}</el-checkbox-button
                  >
                                </el-checkbox-group>-->
                                <el-radio-group v-model="data.followStage" size="small">
                                    <el-radio-button
                                        v-for="item in followArr"
                                        :key="item.number"
                                        :value="item.number"
                                        :label="item.number"
                                    >{{ item.cnVal }}</el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <div style="overflow-y: scroll;height: 100%;">
                        <div class="tip flex-sb">
                            <div class="left">基本信息（ID：{{ data.id }}）</div>
                            <div class="right">入库时间：{{ data.createdTime }}</div>
                        </div>
                        <el-form
                            ref="data"
                            :model="data"
                            :rules="rules"
                            label-width="90px"
                            class="demo-ruleForm"
                        >
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="姓名：" prop="name" class="prop-center">
                                        <el-input
                                            v-model="data.name"
                                            style="min-width:114px"
                                            size="small"
                                            placeholder="请输入内容"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="性别：" prop="sex" class="prop-center">
                                        <el-radio-group
                                            v-model="data.sex"
                                            size="mini"
                                            style="width:140px"
                                        >
                                            <el-radio-button
                                                v-for="item in sexArr"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="年龄：" prop="age" class="prop-center">
                                        <el-input
                                            v-model="data.age"
                                            size="small"
                                            placeholder="请输入数字"
                                            style="min-width:128px"
                                        >
                                            <template slot="append">岁</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="居住地:" prop="residence" class="prop-center">
                                        <!-- {{data.residence[data.residence.length - 1]}} -->
                                        <el-cascader
                                            v-model="data.residence"
                                            size="small"
                                            :show-all-levels="false"
                                            :options="areasOption"
                                            :props="{expandTrigger: 'hover',label: 'name', value: 'name'}"
                                        />
                                        <!-- <el-input v-model="data.age"></el-input> -->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="tip flex-sb">
                                <div class="left">贷款需求</div>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item
                                        label="资金需求："
                                        prop="fundNeeds"
                                        class="prop-center"
                                    >
                                        <el-input
                                            v-model="data.fundNeeds"
                                            style="min-width:114px"
                                            size="small"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                            placeholder="请输入数字"
                                        >
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="18">
                                    <el-form-item
                                        label="意向度:"
                                        prop="purposeLevel"
                                        class="prop-center"
                                    >
                                        <el-radio-group v-model="data.purposeLevel" size="mini">
                                            <el-radio-button
                                                v-for="item in purposeArr"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >
                                                <el-tooltip
                                                    class="item"
                                                    effect="dark"
                                                    :content="item.content"
                                                    placement="bottom-end"
                                                >
                                                    <p>{{ item.cnVal }}</p>
                                                </el-tooltip>
                                            </el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="tip flex-sb" style="margin-bottom:20px">
                                <div class="left">资质</div>
                                <div class="right">
                                    <div class="flex">
                                        <!-- <el-rate v-model="data.qualityLevel"></el-rate> -->
                                        <star :score.sync="data.qualityLevel" />
                                        <div class="quality-text" @click="qualityInfoDialog">可贷点识别</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 车辆 -->
                            <el-row>
                                <el-col :span="4" class="switch-area">
                                    <el-switch
                                        v-model="data.vehicle.has"
                                        active-text="车辆"
                                        :active-value="1"
                                        :inactive-value="0"
                                    />
                                </el-col>
                                <el-col :span="20" class="info-area">
                                    <div v-if="data.vehicle.has == 1">
                                        <el-col
                                            :span="8"
                                            style="text-align:center;padding: 6px 0;"
                                            prop="house.marketPrice"
                                        >
                                            <el-form-item label="裸车价：" prop="vehicle.nakedPrice">
                                                <el-input
                                                    v-model="data.vehicle.nakedPrice"
                                                    style="min-width:150px"
                                                    size="mini"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">万</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <!-- <el-col :span="8" style="text-align:center;padding: 6px 0;">
                    <el-form-item
                      size="mini"
                      label="裸车价："
                      style="margin-bottom:0px"
                      prop="vehicle.nakedPrice"
                    >
                      <el-input
                        placeholder="请输入数字"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        v-model="data.vehicle.nakedPrice"
                      >
                        <template slot="append">万</template>
                      </el-input>
                    </el-form-item>
                                        </el-col>-->
                                        <el-col
                                            :span="16"
                                            style="text-align:center;padding: 6px 0;"
                                        >
                                            <el-form-item
                                                label="付款："
                                                style="margin-bottom:0px"
                                                prop="vehicle.paymentType"
                                            >
                                                <el-radio-group
                                                    v-model="data.vehicle.paymentType"
                                                    size="mini"
                                                    style="width:100%;text-align:left"
                                                >
                                                    <el-radio-button
                                                        v-for="item in carPaymentType"
                                                        :key="item.number"
                                                        :value="item.number"
                                                        :label="item.number"
                                                    >{{ item.cnVal }}</el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                    <div
                                        v-else
                                        style="text-align:center;height:40px;line-height:40px;color:#ccc"
                                    >暂 无 内 容</div>
                                </el-col>
                            </el-row>
                            <!-- 住房 -->
                            <el-row>
                                <el-col :span="4" class="switch-area">
                                    <el-switch
                                        v-model="data.house.has"
                                        active-text="房产"
                                        :active-value="1"
                                        :inactive-value="0"
                                    />
                                </el-col>
                                <el-col :span="20" class="info-area">
                                    <div v-if="data.house.has == 1">
                                        <el-col
                                            :span="8"
                                            style="text-align:center;padding: 6px 0;"
                                            prop="house.marketPrice"
                                        >
                                            <el-form-item
                                                label="房价："
                                                prop="house.marketPrice"
                                                style="margin-bottom:0px"
                                            >
                                                <el-input
                                                    v-model="data.house.marketPrice"
                                                    size="mini"
                                                    style="min-width:150px"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">万</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:left;padding: 6px 0;">
                                            <el-form-item
                                                label="付款："
                                                style="margin-bottom:0px"
                                                prop="house.paymentType"
                                            >
                                                <el-radio-group
                                                    v-model="data.house.paymentType"
                                                    style="min-width: 130px;text-align: left;"
                                                    size="mini"
                                                >
                                                    <el-radio-button
                                                        v-for="item in carPaymentType"
                                                        :key="item.number"
                                                        :value="item.number"
                                                        :label="item.number"
                                                    >{{ item.cnVal }}</el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="类型："
                                                style="margin-bottom:0px"
                                                prop="house.type"
                                            >
                                                <el-select
                                                    v-model="data.house.type"
                                                    size="mini"
                                                    placeholder="请选择"
                                                >
                                                    <el-option
                                                        v-for="item in houseType"
                                                        :key="item.number"
                                                        :label="item.cnVal"
                                                        :value="item.number"
                                                    />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                    <div
                                        v-else
                                        style="text-align:center;height:40px;line-height:40px;color:#ccc"
                                    >暂 无 内 容</div>
                                </el-col>
                            </el-row>
                            <!-- 公积金 -->
                            <el-row>
                                <el-col :span="4" class="switch-area">
                                    <el-switch
                                        v-model="data.gongjijin.has"
                                        active-text="公积金"
                                        :active-value="1"
                                        :inactive-value="0"
                                    />
                                </el-col>
                                <el-col :span="20" class="info-area">
                                    <div v-if="data.gongjijin.has == 1">
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="月缴："
                                                style="margin-bottom:0px"
                                                prop="gongjijin.monthlyFee"
                                            >
                                                <el-input
                                                    v-model="data.gongjijin.monthlyFee"
                                                    style="min-width:150px"
                                                    size="mini"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="年限："
                                                style="margin-bottom:0px"
                                                prop="gongjijin.payYears"
                                            >
                                                <el-input
                                                    v-model="data.gongjijin.payYears"
                                                    size="mini"
                                                    style="min-width:150px"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">年</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="类型："
                                                style="margin-bottom:0px"
                                                prop="gongjijin.type"
                                            >
                                                <el-radio-group
                                                    v-model="data.gongjijin.type"
                                                    size="mini"
                                                    style="min-width:125px"
                                                >
                                                    <el-radio-button
                                                        v-for="item in gongjijin"
                                                        :key="item.number"
                                                        v-model="data.gongjijin.type"
                                                        :value="item.number"
                                                        :label="item.number"
                                                    >{{ item.cnVal }}</el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                    <div
                                        v-else
                                        style="text-align:center;height:40px;line-height:40px;color:#ccc"
                                    >暂 无 内 容</div>
                                </el-col>
                            </el-row>
                            <!-- 社保 -->
                            <el-row>
                                <el-col :span="4" class="switch-area">
                                    <el-switch
                                        v-model="data.shebao.has"
                                        active-text="社保"
                                        :active-value="1"
                                        :inactive-value="0"
                                    />
                                </el-col>
                                <el-col :span="20" class="info-area">
                                    <div v-if="data.shebao.has == 1">
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="年限："
                                                style="margin-bottom:0px"
                                                prop="shebao.payYears"
                                            >
                                                <el-input
                                                    v-model="data.shebao.payYears"
                                                    style="min-width:150px"
                                                    size="mini"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">年</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="类型："
                                                style="margin-bottom:0px"
                                                prop="shebao.type"
                                            >
                                                <el-select
                                                    v-model="data.shebao.type"
                                                    size="mini"
                                                    placeholder="请选择"
                                                >
                                                    <el-option
                                                        v-for="item in shebao"
                                                        :key="item.number"
                                                        :label="item.cnVal"
                                                        :value="item.number"
                                                    />
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                    <div
                                        v-else
                                        style="text-align:center;height:40px;line-height:40px;color:#ccc"
                                    >暂 无 内 容</div>
                                </el-col>
                            </el-row>
                            <!-- 保单 -->
                            <el-row>
                                <el-col
                                    :span="4"
                                    class="switch-area"
                                    style="border-bottom: 1px solid #ccc;"
                                >
                                    <el-switch
                                        v-model="data.baodan.has"
                                        active-text="保单"
                                        :active-value="1"
                                        :inactive-value="0"
                                    />
                                </el-col>
                                <el-col
                                    :span="20"
                                    class="info-area"
                                    style="border-bottom: 1px solid #ccc;"
                                >
                                    <div v-if="data.baodan.has == 1">
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="年缴："
                                                prop="baodan.annualFee"
                                                style="margin-bottom:0px"
                                            >
                                                <el-input
                                                    v-model="data.baodan.annualFee"
                                                    style="min-width:150px"
                                                    size="mini"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">元</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                            <el-form-item
                                                label="年限："
                                                style="margin-bottom:0px;"
                                                prop="baodan.payYears"
                                            >
                                                <el-input
                                                    v-model="data.baodan.payYears"
                                                    style="min-width:150px"
                                                    size="mini"
                                                    placeholder="请输入数字"
                                                    oninput="value=value.replace(/[^\d]/g,'')"
                                                >
                                                    <template slot="append">年</template>
                                                </el-input>
                                            </el-form-item>
                                        </el-col>
                                    </div>
                                    <div
                                        v-else
                                        style="text-align:center;height:40px;line-height:40px;color:#ccc"
                                    >暂 无 内 容</div>
                                </el-col>
                            </el-row>
                            <!-- 跟进内容 -->
                        </el-form>
                    </div>
                    <div>
                        <el-row style="margin-top: 20px;height:auto !important">
                            <el-col :span="24">
                                <div>
                                    <el-input
                                        v-model="data.followContent"
                                        type="textarea"
                                        :autosize="{ minRows: 4, maxRows: 5 }"
                                        placeholder="请输入本次跟进内容"
                                        maxlength="128"
                                        show-word-limit
                                    />
                                </div>
                            </el-col>
                        </el-row>

                        <div class="flex" style="margin-top:10px">
                            <div class="flex back" style="flex:1" @click="back">
                                <!-- <i class="el-icon-back"></i> -->
                                <img width="22" src="@/assets/common/back.png" alt />
                                返回列表
                            </div>
                            <div style="flex:1.3">
                                <el-button type="primary" size="small" @click="saveFollow">保存信息与跟进</el-button>
                                <el-button
                                    v-if="callServices.isEnabled()"
                                    type="primary"
                                    :disabled="callDisabled"
                                    @click="callPhone"
                                >拨打电话</el-button>
                                <el-button
                                    type="primary"
                                    :disabled="callDisabled"
                                    plain
                                    @click="saveInvalidClue"
                                >标记无效</el-button>
                                <!-- <el-button type="primary"  @click="callPhone" :disabled="callClientDisabled">
									拨打电话
                                </el-button>-->
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="7">
                <!-- style="height:calc(100vh - 88px)" -->
                <el-card>
                    <el-tabs v-model="activeName" stretch>
                        <el-tab-pane label="跟进记录" name="0">
                            <div v-if="data.followLogs.length" class="timeblock">
                                <el-timeline>
                                    <el-timeline-item
                                        v-for="(item, index) in data.followLogs"
                                        :key="index"
                                        type="primary"
                                    >
                                        <div class="flex-center">
                                            <div>{{ item.followedTime | getYMDHMS }}</div>
                                            <div>({{ item.follower }})</div>
                                        </div>
                                        <h4>
                                            <span />
                                            <p style="color: #4086ec">{{ item.modifyContent }}</p>
                                            <p>{{ item.followContent }}</p>
                                        </h4>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                            <div v-else>
                                <img src="@/assets/common/no-data/no-records.svg" alt />
                                <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无跟进记录</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="流转记录" name="1">
                            <div v-if="data.transferLogs.length > 0" class="timeblock">
                                <el-timeline>
                                    <el-timeline-item
                                        v-for="(item, index) in data.transferLogs"
                                        :key="index"
                                        type="primary"
                                    >
                                        <div class="flex-center">
                                            <div>{{ item.operatedTime }}</div>
                                            <div>({{ item.operator }})</div>
                                        </div>
                                        <h4>
                                            <span />
                                            {{ item.content }}
                                        </h4>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                            <div v-else>
                                <img src="@/assets/common/no-data/no-records.svg" alt />
                                <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无流转记录</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="线索记录" name="2">
                            <div v-if="data.clueLogs.length > 0" class="timeblock">
                                <el-timeline>
                                    <el-timeline-item
                                        v-for="(item, index) in data.clueLogs"
                                        :key="index"
                                        type="primary"
                                    >
                                        <div class="flex-center">
                                            <div>{{ item.createdTime }}</div>
                                            <div>({{ item.source }})</div>
                                        </div>

                                        <h4>
                                            <span />
                                            <p>信息：{{ item.info }}</p>
                                            <p style="display: flex">星级：{{ item.qualityLevel }}星</p>
                                            <p>意向度：{{ item.purposeLevel }}</p>
                                            <p>跟进结果：{{ item.status }}</p>
                                        </h4>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                            <div v-else>
                                <img src="@/assets/common/no-data/no-records.svg" alt />
                                <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无线索记录</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="通话记录" name="3">
                            <div v-if="personClueDatea.length>0" class="timeblock">
                                <el-timeline>
                                    <el-timeline-item
                                        v-for="(item, index) in personClueDatea"
                                        :key="index"
                                        type="primary"
                                    >
                                        <div class="flex-center">
                                            <div>{{ item.createdTime|getYMDHMS }}</div>
                                            <div>({{ item.callerName }})</div>
                                        </div>
                                        <div class="audio-record">
                                            <div
                                                class="record-item"
                                            >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                            <div class="record-item">
                                                <callAudio
                                                    :src="item.audioUrl"
                                                    :font-size="'10px'"
                                                />
                                            </div>
                                        </div>
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                            <div v-else>
                                <img src="@/assets/common/no-data/no-records.svg" alt />
                                <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无线索记录</div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
        <assign-dialog
            :title="title"
            :show-dialog.sync="showDialog"
            @submitPartment="submitPartment"
        />
        <qualityInfo ref="qualityInfo" />
        <el-dialog
            custom-class="addWxDialog"
            :visible.sync="isShowDialogVisible"
            title="添加微信"
            :modal="false"
        >
            <div class="addWxDialog-input">
                <el-input v-model="addWxGreetings" placeholder="请输入打招呼语" size="small " />
            </div>
            <div class="addWxDialog-btn">
                <el-button type="primary" @click="addWx">添加</el-button>
            </div>
        </el-dialog>

        <!-- 线索弹窗 -->
        <clue-loading ref="clueFeedback" @saveUnusertag="saveUnusertag" />
    </div>
</template>

<script>
// import {
// 	personClueData
// } from "../../assets/js/falseData.js";
// import callAudio from '../../components/callAudio.vue'
import areas from '@/assets/js/areaServices';
import {
    client,
    client_ordinary,
    client_important,
    client_public,
    client_owner,
    client_followed_log1,
    addClientWx,
} from '@/api/my_client';
import { InvalidTagClient } from '@/api/invalid-client';
import { getYMDHMS } from '@/assets/js/time.js';
export default {
    components: {
        callAudio: () => import('../../components/callAudio'),
        assignDialog: () => import('./components/assignDialog'),
        star: () => import('@/components/templates/star'),
        qualityInfo: () => import('@/components/templates/quality-info'),
        ClueLoading: () =>
            import('@/views/turnsCallList/components/ClueLoading'),
    },
    inject: ['callClientChange'],
    data() {
        return {
            wechatServiceEnabled: 0,
            // 添加微信对话框是否可见
            isShowDialogVisible: false,
            // 添加微信用户语
            addWxGreetings: '',
            callDisabled: false,
            data: {
                clueLogs: [],
                followLogs: [],
                transferLogs: [],
                followStage: 0,
                sex: '',
                house: {
                    has: 0,
                    marketPrice: 0,
                    type: 0,
                    paymentType: 0,
                },
                vehicle: {
                    has: 0,
                    nakedPrice: 0,
                    paymentType: 0,
                },
                shebao: {
                    has: 0,
                    type: 0,
                    payYears: 0,
                },
                gongjijin: {
                    has: 0,
                    type: 0,
                    payYears: 0,
                    monthlyFee: 0,
                },
                baodan: {
                    has: 0,
                    annualFee: 0,
                    payYears: 0,
                },
            },
            followArr: [],
            rules: {
                name: [
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                        required: true,
                    },
                    {
                        validator: this.formValidation.name,
                        trigger: 'blur',
                    },
                ],
                age: [
                    {
                        validator: this.formValidation.ldq,
                        trigger: 'blur',
                    },
                ],
                fundNeeds: [
                    {
                        validator: this.formValidation.ldyw,
                        trigger: 'blur',
                    },
                ],
                vehicle: {
                    nakedPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'change',
                        },
                    ],
                },
                house: {
                    marketPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'blur',
                        },
                    ],
                },
                gongjijin: {
                    monthlyFee: [
                        {
                            validator: this.formValidation.ldsw,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                shebao: {
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                baodan: {
                    annualFee: [
                        {
                            validator: this.formValidation.ldyy,
                            trigger: 'change',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
            },
            sexArr: [],
            areasOption: [],
            purposeArr: [],
            carPaymentType: [],
            houseType: [],
            gongjijin: [],
            shebao: [],
            baodan: [],
            activeName: '0',
            showDialog: false, //展示选择部门
            title: '选择要转移的对象',
            // personClueData
            personClueDatea: [],
            eed: '',
        };
    },
    props: {
        personClueData: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.followArr = this.common.allValueNeed('client-follow-stage');
        this.sexArr = this.common.allValueNeed('sex');
        this.purposeArr = this.common.allValueNeed('loan-purpose-level');
        this.wechatServiceEnabled = JSON.parse(
            window.sessionStorage.getItem('config')
        ).wechatServiceEnabled;
        this.purposeArr.forEach((item) => {
            if (item.number == 0) {
                item.content = '沟通过贷款问题，或暂无意向';
            } else if (item.number == 1) {
                item.content = '有贷款意向，还存在较多顾虑';
            } else if (item.number == 2) {
                item.content = '有明确贷款意向，不急需用钱';
            } else if (item.number == 3) {
                item.content = '迫切需要贷款，急需用钱';
            }
        });
        this.carPaymentType = this.common.allValueNeed('vehicle-pay-type');
        this.houseType = this.common.allValueNeed('house-type');
        this.gongjijin = this.common.allValueNeed('gongjijin-pay-type');
        this.shebao = this.common.allValueNeed('shebao-type');
        this.baodan = this.common.allValueNeed('loan-purpose-level');
        this.areasOption = areas.getAll();
    },
    mounted() {
        this.$EventBus.$on('CallEventName_1', this.callEventNameHandle);
    },
    beforeDestroy() {
        this.$EventBus.$off('CallEventName_1', this.callEventNameHandle);
    },
    methods: {
        // 添加微信弹出框
        openAddWxDialog() {
            this.addWxGreetings = '';
            this.isShowDialogVisible = true;
        },
        addWx() {
            this.isShowDialogVisible = false;

            addClientWx({
                mobileNumber: this.common.hidePhoneNumber(
                    this.data.mobileNumber,
                    1
                ),
                message: this.addWxGreetings,
                clientId: this.data.id,
                clientName: this.data.name,
            }).then((res) => {
                if (res.code !== '0') return this.$message.error(res.message);
                this.$message({
                    message: `添加成功，今天还可以添加${res.data.remainderCount}个微信`,
                    type: 'success',
                });
            });
        },

        // 保存为无效线索
        saveInvalidClue() {
            this.$refs.clueFeedback.init(3, 3);
            console.log('保存为无效线索');
        },
        saveUnusertag(data) {
            InvalidTagClient({
                tag: data,
                clientId: this.data.id,
            }).then((res) => {
                this.back();
            });
        },

        callPhone() {
            console.log('call');
            this.$store.commit('callJudge', true);
            this.callClientChange();
        },
        init(row) {
            console.log('跟进初始化', row);
            this.getCilentIndo(row.id);
        },
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        getCilentIndo(id) {
            client({
                clientId: id,
            }).then((res) => {
                if (res.code !== '0') {
                    this.$message.error(res.message);
                    // this.$router.push('/my-clue')
                    return;
                }
                let data = res.data;
                console.log(data, 'getCilentIndo');

                // let uuid = ''
                // uuid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                // let callUUid = ''
                // callUUid = (uuid + uuid + "-" + uuid + "-" + uuid + "-" + uuid + "-" + uuid + uuid + uuid)

                function guid() {
                    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                        /[xy]/g,
                        function (c) {
                            let r = (Math.random() * 16) | 0,
                                v = c == 'x' ? r : (r & 0x3) | 0x8;
                            return v.toString(16);
                        }
                    );
                }

                let callUUid = guid();

                let callParams = {
                    phoneNumber: this.common.hidePhoneNumber(
                        data.mobileNumber,
                        1
                    ),
                    clueId: '',
                    clientId: data.id,
                    callUUID: callUUid,
                    // // followSaved: '',
                    calledName: data.name,
                    token: sessionStorage.getItem('sipToken'),
                };
                this.$store.commit('callSatausChange', callParams);

                this.personClueDatea = data.callLogs;
                data.createdTime = getYMDHMS(data.createdTime);
                for (let i = 0; i < data.followLogs.length; i++) {
                    data.followLogs[i].followedTime = getYMDHMS(
                        data.followLogs[i].followedTime
                    );
                }
                //数据转为可读或可用
                for (let i = 0; i < data.transferLogs.length; i++) {
                    data.transferLogs[i].operatedTime = getYMDHMS(
                        data.transferLogs[i].operatedTime
                    );
                }
                // 处理ClientInformation线索日志字段
                for (let i = 0; i < data.clueLogs.length; i++) {
                    data.clueLogs[i].createdTime = getYMDHMS(
                        data.clueLogs[i].createdTime
                    );
                    let clueStatus = this.common.allValueNeed('clue-status');
                    let clueStatusVal = clueStatus.find((item) => {
                        return data.clueLogs[i].status == item.number;
                    });
                    console.log(clueStatusVal);
                    if (clueStatusVal)
                        data.clueLogs[i].status = clueStatusVal.cnVal;
                    // 意向度-意向线索-等级
                    let loanPurposeLevel =
                        this.common.allValueNeed('loan-purpose-level');
                    let PurposeLevelVal = loanPurposeLevel.find((item) => {
                        return data.clueLogs[i].purposeLevel == item.number;
                    });
                    if (PurposeLevelVal)
                        data.clueLogs[i].purposeLevel = PurposeLevelVal.cnVal;
                }
                this.data = data;
                console.log(data);
            });
        },
        zhongyao() {
            console.log(this.data.id);
            if (this.data.important == 1) {
                client_ordinary({
                    clientId: this.data.id,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('成功转为普通客户');
                        this.getCilentIndo(this.data.id);
                    } else {
                        this.$message.error(res.error);
                    }
                });
            } else {
                client_important({
                    clientId: this.data.id,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('成功转为重要客户');
                        this.getCilentIndo(this.data.id);
                    } else {
                        this.$message.error(res.error);
                    }
                });
            }
        },
        dropPublicSea() {
            client_public({
                clientIds: [this.data.id],
            }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('已丢入公海');
                    this.back();
                    this.$emit('refresh');
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        transfer() {
            this.showDialog = true;
        },
        // 子组件选完部门传回来的id
        submitPartment(val) {
            client_owner({
                clientIds: [this.data.id],
                inStaffId: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '已将客户转移',
                        type: 'success',
                    });
                    this.showDialog = false;
                    this.back();
                    this.$emit('refresh');
                } else {
                    this.$message.error('参数不可为空');
                }
            });
        },
        back() {
            this.$emit('closeFollow');
            this.$refs.data.resetFields();
        },
        // handleClick(tab){
        //   console.log(tab, event);
        // }
        saveFollow() {
            this.$refs.data.validate((valid) => {
                if (valid) {
                    console.log(typeof this.data.residence);
                    if (typeof this.data.residence == 'object') {
                        this.data.residence =
                            this.data.residence[this.data.residence.length - 1];
                    }
                    let submitData = {
                        clientId: this.data.id,
                        followStage: this.data.followStage,
                        fundNeeds: this.data.fundNeeds,
                        name: this.data.name,
                        sex: this.data.sex,
                        age: this.data.age,
                        residence: this.data.residence,
                        qualityLevel: this.data.qualityLevel,
                        purposeLevel: this.data.purposeLevel,
                        followContent: this.data.followContent,
                        house: this.data.house,
                        shebao: this.data.shebao,
                        vehicle: this.data.vehicle,
                        gongjijin: this.data.gongjijin,
                        baodan: this.data.baodan,
                        callUUID: this.$store.state.callStatus.callUUID,
                    };
                    console.log(submitData);
                    client_followed_log1(submitData).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '修改成功',
                                type: 'success',
                            });
                            this.back();
                            this.$emit('refresh');
                        } else {
                            this.$message.error(res.error);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        qualityInfoDialog() {
            this.$refs.qualityInfo.init();
        },
        //  呼叫事件处理
        callEventNameHandle(val) {
            this.callDisabled = val;
            console.log(val, 'fuckval');
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';

.el-row {
    height: 100%;

    .el-col-17,
    .el-col-7 {
        height: 100%;

        .el-card {
            height: 100%;

            /deep/ .el-card__body {
                height: 100%;
            }
        }
    }
}

.flex-center {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #aaaaaa;
}

.flex {
    display: flex;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

.left-area {
    /deep/ .el-card__body {
        display: flex;
        flex-direction: column;
    }
}

.follow-page {
    height: 100%;
    padding: 10px;

    .title-info {
        border-bottom: 1px solid #ccc;
        padding-bottom: 9px;

        .title-left {
            div {
                margin-right: 5px;
            }
        }

        .title-right {
            div {
                &:not(:first-child) {
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    font-size: 20px;
                    color: #f74343;
                }
            }
        }
    }

    .tip {
        margin-top: 10px;
        background-color: rgba(249, 249, 249, 1);
        height: 32px;
        line-height: 32px;
        padding: 0 10px;

        .left {
            font-size: 14px;
        }

        .right {
            font-size: 12px;
            color: #7f7f7f;
        }
    }
}

/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px;
}

/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #4086ec;
    background-color: #ecf3fd;
    box-shadow: 0 0px 0px #ccc;
    border-color: #dcdfe6;
}

/deep/ .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: #dcdfe6;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #4086ec;
    background-color: #ecf3fd;
    box-shadow: 0 0px 0px #ccc;
    border-color: #dcdfe6;
}

/deep/ .el-radio-button__inner {
    color: #aaa;
}

/deep/ .el-form-item__content .el-input-group {
    vertical-align: baseline;
}

// /deep/ .el-input-group__append {
//   padding: 0 10px;
// }
.xin {
    font-size: 30px;
    vertical-align: middle;
    color: #d7d7d7;
    cursor: pointer;
}

.xin:hover {
    color: #ffafb8;
}

.xin:active {
    color: #e96e7d;
}

.ping {
    color: #ff8190;
}

.el-form-item {
    // margin-top: 10px;
    // margin-bottom: 0;
}

.switch-area {
    height: 50px;
    line-height: 50px;
    padding-left: 26px;
    border: 1px solid #ccc;
    border-bottom: none;
}

.info-area {
    border: 1px solid #ccc;
    border-left: 0;
    border-bottom: none;
    line-height: 50px;
    height: 50px;
}

.prop-center {
    margin-top: 10px;
    margin-bottom: 0;
}

/deep/ .el-card__body {
    // overflow-y: scroll;
    height: 100%;
}

.timeblock {
    overflow-y: scroll;
    height: calc(100vh - 175px);

    // overflow-y: scroll;
    // 		height: 88%;
    .audio-record {
        margin-top: 10px;
        // margin: 5px 0 5px 0;
        color: #ffffff;
        width: 300px;
        height: 84px;
        background-color: #3f81e7;

        .record-item {
            margin-left: 10px;
            padding-top: 10px;
        }
    }

    ::v-deep .el-timeline-item__tail {
        // border-left: 1px solid #3f85eb;
    }

    ::v-deep.el-timeline-item__node--primary {
        left: 0px;
        // border: 2px solid #3f85eb;
        // background: transparent;
    }

    ::v-deep .el-timeline-item__tail {
        top: 10px;
        left: 7px;
        height: 100%;
    }

    time,
    span {
        color: #c9c9c9;
    }

    ::v-deep .el-timeline-item__timestamp {
        float: left;
    }

    h4 {
        background: rgba(246, 249, 255, 1);
        border-radius: 4px;
        color: #555555;
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
        position: relative;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: left !important;
        margin-bottom: 0 !important;

        span {
            display: inline-block;
            width: 5px;
            height: 100%;
            background: #3f85eb;
            border-radius: 10px 0 0 10px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.back {
    cursor: pointer;
}

/deep/ .el-form-item__content {
    text-align: left;
}

/deep/ .rate .star-wrap .icon-star {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}

/deep/ .rate .star-wrap .icon-star-o {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}

.quality-text {
    color: #aaaaaa;
    font-size: 14px;

    &:hover {
        color: #4086ec;
        cursor: pointer;
    }
}

/deep/ .el-timeline-item__wrapper {
    top: 0;
}

/deep/ .el-form-item__error {
    min-width: 143px;
}

/deep/ .el-input-group__append {
    padding: 0 10px;
}
// 添加微信
.addWxBtn:hover {
    cursor: pointer;
}
// 添加微信对话框
/deep/ .addWxDialog {
    top: 220px;
    left: 60%;
    width: 360px;
    .el-dialog__header {
        padding: 20px 20px 15px;
        text-align: center;
    }
    .el-dialog__body {
        display: flex;
        padding: 0px 24px 20px;
        .addWxDialog-input {
            flex: 100;
            padding-right: 5px;
        }
        .addWxDialog-btn {
            flex: 20;
            padding-left: 5px;
        }
    }
}
// .timeblock {
// 	overflow-y: scroll;
// 	height: 100%;
// 	padding-top: 10px;

// 	::v-deep .el-timeline-item__tail {
// 		border-left: 1px solid #3f85eb;
// 	}

// 	::v-deep .el-timeline-item {}

// 	::v-deep.el-timeline-item__node--primary {
// 		border: 2px solid #3f85eb;
// 		background: #fff;
// 	}

// 	::v-deep .el-timeline-item__tail {
// 		top: 10px;
// 		left: 6.5px;
// 		// height: 80%;
// 	}

// 	h4 {
// 		color: #555;
// 	}

// 	time,
// 	span {
// 		color: #c9c9c9;
// 	}

// 	::v-deep .el-timeline {
// 		// border: 1px solid black;
// 		height: 100%;
// 		overflow-y: scroll;
// 	}

// 	.audio-record {
// 		margin-top: 10px;
// 		// margin: 5px 0 5px 0;
// 		color: #FFFFFF;
// 		width: 300px;
// 		height: 84px;
// 		background-color: #3f81e7;

// 		.record-item {
// 			margin-left: 10px;
// 			padding-top: 10px;
// 		}
// 	}
// }
</style>
